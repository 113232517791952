import axios from '@axios'

export default {
  namespaced: true,
  state: {
    activeSociete: null,
    soldeActiveSociete: null,
  },
  getters: {
    getSocieteList() {
      const societeList = JSON.parse(localStorage.getItem('userData')).societes.map(entity => ({ idSociete: entity.idSociete, societe: entity.societe }))
      return societeList
    },
    getActiveSociete(state) {
      if (state.activeSociete != null) {
        return state.activeSociete
      }
      state.activeSociete = JSON.parse(localStorage.getItem('activeSociete'))
      return JSON.parse(localStorage.getItem('activeSociete'))
    },
    getSoldeActiveSociete(state) {
      if (state.soldeActiveSociete != null) {
        return state.soldeActiveSociete
      }
      return localStorage.getItem('soldeActiveSociete')
    },
  },
  mutations: {
    setActiveSociete: (state, value) => {
      state.activeSociete = value
    },
    setSoldeActiveSociete: (state, value) => {
      state.soldeActiveSociete = value
    },
  },
  actions: {
    updateActiveSociete({ commit }, societe) {
      commit('setActiveSociete', societe)
    },
    getSolde({ commit }, { idSociete }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/societe/getSolde/${idSociete}`)
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('soldeActiveSociete', response.data.toFixed(2))
              commit('setSoldeActiveSociete', response.data.toFixed(2))
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getEvolutionSolde(info, { idSociete, limit, page }) {
      return new Promise((resolve, reject) => {
        const url = `/societe/getSoldeRecap/${idSociete}`
        const params = { }
        if (limit) params.limit = limit
        if (page) params.page = page

        axios
          .get(url, { params })
          .then(response => {
            if (response.status === 200) {
              resolve(response.data)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
