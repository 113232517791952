import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueMatomo from 'vue-matomo'

import VueLuxon from 'vue-luxon'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueLuxon)

const config = require('../config')

Vue.use(VueMatomo, {
  host: 'https://matomo.absystech.net/',
  siteId: 3,
  trackerFileName: 'matomo',
  // Enables automatically registering pageviews on the router
  // eslint-disable-next-line object-shorthand
  router: router,
  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  requireCookieConsent: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,
  preInitActions: [],
  trackSiteSearch: false,
})

// Feather font icon - For form-wizard
require('@core/assets/fonts/feather/iconfont.css')

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('@core/scss/vue/libs/vue-select.scss')
require('@core/scss/vue/libs/vue-good-table.scss')

Vue.config.productionTip = !config.devTools
Vue.config.devTools = config.devTools

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
