export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import('@/views/auth/Profil.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'MY_PROFIL',
    },
  },

  // ? Code de la route vers Home commentée. Pas d'utilité pour l'instant
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('@/views/Home.vue'),
  //   meta: {
  //     requiresAuth: true,
  //     pageTitle: 'Home',
  //   },
  // },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reinit-password',
    name: 'reinit-password',
    component: () => import('@/views/auth/ReinitPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
