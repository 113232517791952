export default [
  {
    path: '/factures',
    name: 'factures',
    component: () => import('@/views/factures/listing/FacturesList.vue'),
    meta: {
      requiresAuth: true,
      requireDroit: 'facture',
      pageTitle: 'MY_FACTURES',
    },
  },
]
