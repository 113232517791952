import axios from '@axios'

export default {
  namespaced: true,
  state: {
    requestStatusSelected: { status: null },
    notifications: null,
    entityRequestList: [],
    statusRequestList: ['free', 'fixing', 'wait', 'done', 'payee', 'annulee'],
    statusDefaultRequestList: ['fixing', 'free', 'wait'],
    severityRequestList: ['detail', 'genant', 'bloquant'],
    hotline: {
      idHotline: '',
      hotline: '',
      etat: '',
      date: '',
      poleConcerne: '',
      facturationTicket: '',
      okFacturation: '',
      urgence: '',
      charge: '',
      user: null,
      contact: null,
      pj: false,
    },
    creditConsomme: 0,
    interactions: [],
  },
  getters: {
    getRawFilterParams() {
      return JSON.parse(localStorage.getItem('rawFilter'))
    },
    getFormatedFilterParams() {
      return JSON.parse(localStorage.getItem('formatedFilter'))
    },
    getRequestStatusSelected(state) {
      return state.requestStatusSelected
    },
    getSeverityRequestList(state) {
      return state.severityRequestList
    },
    getStatusRequestList(state) {
      return state.statusRequestList
    },
    getStatusDefaultRequestList(state) {
      return state.statusDefaultRequestList
    },
    getNotificationsSociete(state) {
      if (state.notifications != null) {
        return state.notifications
      }
      return JSON.parse(localStorage.getItem('notificationsActiveSociete'))
    },
    getHotline(state) {
      return state.hotline
    },
    getCreditConsomme(state) {
      return state.creditConsomme
    },
    getInteractions(state) {
      return state.interactions
    },
  },
  mutations: {
    setRequestStatusSelected: (state, value) => {
      state.requestStatusSelected = value
    },
    setNotifications: (state, value) => {
      state.notifications = value
    },
    setEntityRequestList: (state, value) => {
      state.entityRequestList = value
    },
    setHotline(state, value) {
      state.hotline = value
    },
    setHotlinePJ(state, value) {
      state.hotline.pj = value
    },
    setCreditConsomme(state, value) {
      state.creditConsomme = value
    },
    setInteractions(state, value) {
      state.interactions = value
    },
  },
  actions: {
    setRawFilterParams({ commit }, params) {
      localStorage.setItem('rawFilter', JSON.stringify(params))
    },
    setFormatedFilterParams({ commit }, params) {
      localStorage.setItem('formatedFilter', JSON.stringify(params))
    },
    setRequestStatusSelected({ commit }, params) {
      commit('setRequestStatusSelected', params)
    },
    getEntityRequestList({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/hotlines', { params }).then(
          response => {
            commit('setEntityRequestList', response.data)
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    getNotificationsHotline({ commit }, { idSociete }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/hotlines/hotlineByUser/${idSociete}`)
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('notificationsActiveSociete', JSON.stringify(response.data))
              commit('setNotifications', response.data)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    insert(infos, formData) {
      return new Promise((done, fail) => {
        axios
          .post('/hotlines', formData, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              done(response)
            } else {
              fail()
            }
          })
          .catch(() => {
            fail()
          })
      })
    },
    fetchOne({ commit }, id) {
      return new Promise((done, fail) => {
        axios
          .get(`/hotlines/${id}`)
          .then(response => {
            commit('setHotline', response.data.hotline)
            commit('setHotlinePJ', response.data.hotlinePJ)
            commit('setCreditConsomme', response.data.creditConsomme)
            commit('setInteractions', response.data.interactions)
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },
    newInteraction({ state }, formData) {
      return new Promise((done, fail) => {
        axios
          .post('/interaction', formData, { Headers: { 'Content-Type': 'multipart/form-data' } })
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              Object.keys(response.data.hotline).forEach(element => {
                state.hotline[element] = response.data.hotline[element]
              })
              state.interactions.unshift(response.data.interaction)
              done()
            } else {
              fail()
            }
          })
          .catch(() => {
            fail()
          })
      })
    },
    acceptRefusFacturation({ state }, { idHotline, okFacturation, detail }) {
      return new Promise((done, fail) => {
        axios
          .post('/hotlines/acceptRefusFacturation', { idHotline, okFacturation, detail })
          .then(response => {
            Object.keys(response.data.hotline).forEach(element => {
              state.hotline[element] = response.data.hotline[element]
            })

            state.interactions.unshift(response.data.interaction)
            done()
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    acceptRefusResolution({ state }, {
      idHotline, validationCloture, indiceSatisfaction, detail,
    }) {
      return new Promise((done, fail) => {
        axios
          .post('/hotlines/acceptRefusResolution', {
            idHotline, validationCloture, indiceSatisfaction, detail,
          })
          .then(response => {
            Object.keys(response.data.hotline).forEach(element => {
              state.hotline[element] = response.data.hotline[element]
            })

            state.interactions.unshift(response.data.interaction)
            done()
          })
          .catch(error => {
            fail(error)
          })
      })
    },
    downloadHotlinePJ({ state }, idHotline) {
      return new Promise((done, fail) => {
        axios
          .get(`/hotlines/pj/${idHotline}`)
          .then(response => {
            done(response.data)
          })
          .catch(error => fail(error))
      })
    },
  },
}
