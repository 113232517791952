import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {
    getUserDatas() {
      const userDatas = JSON.parse(localStorage.getItem('userData')).societes
      return userDatas
    },
  },
  mutations: {},
  actions: {
    reinitPassword(infos, { username }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/utilisateurs/resetPassword', { email: username })
          .then(response => {
            if (response.status === 201) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updatePassword(infos, { password }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/utilisateurs/updatePassword', { password })
          .then(response => {
            if (response.status === 201) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateAccount(infos, { id, emailFacture, factureParMail }) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/utilisateurs/${id}`, { emailFacture, factureParMail })
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },

  },
}
