import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

import application from './modules/application'
import jwt from './modules/jwt'
import societe from './modules/societe'
import hotline from './modules/hotline'
import utilisateur from './modules/utilisateur'
import facture from './modules/facture'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,

    application,
    jwt,
    societe,
    hotline,
    utilisateur,
    facture,
  },
  strict: process.env.DEV,
})
