import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    canAccess({}, { idSociete, droit }) {
      return new Promise(resolve => {
        const user = JSON.parse(localStorage.getItem('userData'))
        if (user !== false) {
          const haveDroit = user.droits.filter(el => el.droit === droit && el.can === true && el.societe === idSociete)
          if (haveDroit.length > 0) {
            resolve(true)
          } else {
            resolve(false)
          }
        } else {
          resolve(false)
        }
      })
    },
    passwordRules() {
      return new Promise((resolve, reject) => {
        axios
          .get('/passwordRules', { params: { unRequiredAuth: true } })
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    mentionsLegales() {
      return new Promise((resolve, reject) => {
        axios
          .get('/mentionsLegales', { params: { codename: localStorage.getItem('codename') } })
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    docUtilisateur() {
      return new Promise((resolve, reject) => {
        axios
          .get('/help/getDocUtilisateur', { params: { unRequiredAuth: true } })
          .then(response => {
            if (response.status === 200 || response.status === 403) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
