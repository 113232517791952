const env = process.env.NODE_ENV || 'development'
if (env === 'development') {
  console.log('ENV: development')
}
module.exports = {
  devTools: process.env.VUE_APP_DEVTOOLS,
  api: {
    baseUrl: process.env.VUE_APP_BASE_URL,
  },
  urlTeamViewer: process.env.VUE_APP_TEAMVIEWER_URL,
}
