import axios from '@axios'

export default {
  namespaced: true,
  state: {
    userLogged: {},
    logged: false,
  },
  getters: {},
  mutations: {
    setLogged: (state, value) => {
      state.logged = value
    },
  },
  actions: {
    checkLogin({ commit }, { username, password, codename }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/login', {
            username, password, codename, unRequiredAuth: true,
          })
          .then(response => {
            if (response.status === 200) {
              commit('setLogged', true)
              axios.defaults.headers.Authorization = `Bearer ${JSON.stringify(response.data.token).slice(1, -1)}`
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout() {
      return new Promise((resolve, reject) => {
        localStorage.removeItem('accessToken')
        // localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        localStorage.removeItem('activeSociete')
        localStorage.removeItem('soldeActiveSociete')
        localStorage.removeItem('notificationsActiveSociete')
        localStorage.removeItem('formatedFilter')
        localStorage.removeItem('rawFilter')

        // Remove userData from localStorage
        localStorage.removeItem('userData')
        resolve(true)
      })
    },
    me() {
      return new Promise((resolve, reject) => {
        axios
          .get('/me')
          .then(response => {
            if (response.status === 200) {
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
