import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    fetchAll({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/factures', { params }).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          },
        )
      })
    },
    downladPDF({ }, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`factures/pj/${id}`)
          .then(
            response => {
              if (response.status === 200) {
                resolve(response.data)
              } else {
                reject(response.data)
              }

            },
            error => {
              reject(error)
            },
          )
          .catch(e => reject(e))
      })
    },
  },
}
