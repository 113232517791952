import Vue from 'vue'
import VueRouter from 'vue-router'

import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

import hotline from './routes/hotline'
import societe from './routes/societe'
import factures from './routes/factures'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...societe,
    ...hotline,
    ...factures,
    ...pages,
    {
      path: '/',
      redirect: { name: 'new-request' },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!isLoggedIn) {
      next({ name: 'auth-login' })
    } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (to.meta.requireDroit !== undefined) {
    const user = JSON.parse(localStorage.getItem('userData'))

    const haveDroit = user.droits.filter(el => el.droit === to.meta.requireDroit && el.can === true)
    if (haveDroit.length === 0) {
      next({ name: 'error-404' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
