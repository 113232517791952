export default [
  {
    path: '/balance',
    name: 'balance',
    component: () => import('@/views/Balance.vue'),
    meta: {
      requiresAuth: true,
      requireDroit: 'solde',
      pageTitle: 'SOLDE',
    },
  },
]
