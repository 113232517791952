export default [
  {
    path: '/requests/new',
    name: 'new-request',
    component: () => import('@/views/requests/NewRequest.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'NEW_REQUEST',
    },
  },
  {
    path: '/requests/personal',
    name: 'requests-personal',
    component: () => import('@/views/requests/listing/PersonnalRequestList.vue'),
    meta: {
      requiresAuth: true,
      requireDroit: 'mes_demandes',
      pageTitle: 'MY_REQUESTS',
    },
  },
  {
    path: '/requests/entity',
    name: 'requests-entity',
    component: () => import('@/views/requests/listing/EntityRequestList.vue'),
    meta: {
      requiresAuth: true,
      requireDroit: 'toutes_les_demandes',
      pageTitle: 'ALL_REQUESTS',
    },
  },
  {
    path: '/requests/:id',
    name: 'request-detail',
    component: () => import('@/views/requests/details/RequestDetails.vue'),
    meta: {
      requiresAuth: true,
    },
  },
]

